<template>
  <div class="wallet-history">
    <div class="container-fluid mt--5">
        <history-manage/>
    </div>
  </div>
</template>

<script>

import HistoryManage from './components/HistoryManage.vue'
export default {
  components:{
    HistoryManage
  }
  
};
</script>

<style lang="scss">

</style>