<template>
  <div class="wallet-receipt">
    <el-dialog
      title="Topup Detail"
      :visible.sync="dialogDepositDetail"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :width="isMobile ? '95%' : '50%'"
    >
      <div v-if="Object.keys(dataDeposit).length > 0">
        <div class="card mb-3">
          <div class="card-body p-2">
            <el-descriptions class="margin-top" :column="1" size="small" border>
              <el-descriptions-item label="Status">
                <el-tag v-if="dataDeposit.status == 'process'" size="mini"
                  >Process</el-tag
                >
                <el-tag
                  v-if="dataDeposit.status == 'pending'"
                  type="warning"
                  size="mini"
                  >Pending</el-tag
                >
                <el-tag
                  v-if="dataDeposit.status == 'success'"
                  type="success"
                  size="mini"
                  >Success</el-tag
                >
                <el-tag
                  v-if="dataDeposit.status == 'topupfail'"
                  type="danger"
                  size="mini"
                  >Topup Fail</el-tag
                >
                <el-tag
                  v-if="dataDeposit.status == 'cancel'"
                  type="danger"
                  size="mini"
                  >Cancel</el-tag
                >
                <el-tag
                  v-if="dataDeposit.status == 'cancelfail'"
                  type="danger"
                  size="mini"
                  >Cancel Fail</el-tag
                >
              </el-descriptions-item>
              <el-descriptions-item v-if="upgradePlus" label="Wallet ID">{{
                dataDeposit.walletId
              }}</el-descriptions-item>
              <el-descriptions-item label="Currency">{{
                dataDeposit.currency
              }}</el-descriptions-item>

              <el-descriptions-item label="Date Time">{{
                dataDeposit.createdDateFormat
              }}</el-descriptions-item>
              <el-descriptions-item label="Customer">{{
                dataDeposit.memberCode
              }}</el-descriptions-item>
              <el-descriptions-item label="Sender name">
                {{ dataDeposit.senderName }}
              </el-descriptions-item>
              <el-descriptions-item label="Acc. / IBAN">{{
                dataDeposit.accountNo
              }}</el-descriptions-item>
              <el-descriptions-item label="Reason">{{
                dataDeposit.reason
              }}</el-descriptions-item>
              <el-descriptions-item label="Reference">{{
                dataDeposit.customerRef
              }}</el-descriptions-item>
              <el-descriptions-item label="Deposit by">{{
               chanel[dataDeposit.chanel]
              }}</el-descriptions-item>
              <el-descriptions-item label="Topup Amount"
                ><span style="font-weight: bold;">{{
                  dataDeposit.amountOut ? numberFormat(dataDeposit.amountOut) : ""
                }}
                {{ dataDeposit.currency }}</span></el-descriptions-item
              >
            </el-descriptions>
          </div>
        </div>
        <div class="card mb-3">
          <div class="card-body p-2 text-center">
            <el-image :src="fileBase64">
            
              <div slot="error" class="image-slot">
                <div slot="error" class="el-image__error">No Slip</div>
              </div>
            </el-image>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Auth from "@/api/auth";
import { isMobile } from "@/utils/helpers";
import Utils from "@/utils";
export default {
  computed: {
    isMobile() {
      return isMobile();
    },
    upgradePlus() {
      return this.$store.getters.feature ? this.$store.getters.feature.upgrade : false;
    },
  },
  data() {
    return {
      dialogDepositDetail: false,
      dataDeposit: {},
      fileBase64: "",
      baseUrl: process.env.VUE_APP_SERVER_BASE_API,
      baseService: process.env.VUE_APP_SERVER_BASE_API_VERSION,
      chanel: {
        api: "API",
        card: "Card",
        slip: "Bank Transfer"
      }
    };
  },
  methods: {
    getImage(fileName) {
      let url = `${this.baseUrl}${this.baseService}/v1/wallet/slip/${fileName}`;
      axios.get(url, {
        responseType: "arraybuffer",
        headers: {
          authorization: Auth.getToken(),
        },
      }).then((result)=>{
        let raw = Buffer.from(result.data).toString("base64");
        this.fileBase64 = "data:" + result.headers["content-type"] + ";base64," + raw;
      });
    },
    open(data) {
      this.fileBase64 = "";
      this.getImage(data.slipName)
      this.dataDeposit = data;
      this.dialogDepositDetail = true;
    },
    numberFormat(number) {
      return Utils.numberFormat(number, 2);
    },
  },
};
</script>

<style>
</style>