<template>
  <div class="wallet-history-manage">
    <div class="card mb-3">
      <div class="card-header">
        <h3 class="mb-0"><i class="el-icon-time"></i> History</h3>
        <div class="position-absolute top-2 right-2 pt-1 pr-1">
          <el-button
            v-if="!showControl"
            type="text"
            @click="$router.push({ path: '/wallet/history' })"
          >
            Show all history
          </el-button>
        </div>
      </div>
      <div class="card-body">
        <div class="search-area" v-if="showControl">
          <el-select v-model="query.filterType" style="width: 100px">
            <el-option value="all" label="All"></el-option>
            <el-option value="pending" label="Pending"></el-option>
            <el-option value="process" label="Process"></el-option>
            <el-option value="paid" label="Paid"></el-option>
            <el-option value="cancel" label="Cancel"></el-option>
          </el-select>

          <el-date-picker
            v-model="dateSearch"
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            format="yyyy-MM-dd"
          >
          </el-date-picker>
          <el-button
            slot="append"
            type="primary"
            icon="el-icon-search"
            :loading="loading"
            :disabled="loading"
            @click="getPaymentHistory()"
            >Search</el-button
          >
        </div>
        <div v-loading="loading">
          <el-tabs type="card" v-model="activeName">
            <el-tab-pane label="Transfer" name="transfer">
              <el-table
                border
                :data="tableTransfer"
                size="mini"
                style="width: 100%"
              >
                <el-table-column
                  prop="created"
                  label="Date"
                  width="150"
                  fixed="left"
                >
                </el-table-column>
                <el-table-column prop="from" label="From" width="70">
                </el-table-column>
                <el-table-column prop="to" label="To" width="70">
                </el-table-column>
                <el-table-column prop="name" label="Name" min-width="180">
                </el-table-column>
                <el-table-column prop="amount" label="Amount" width="100">
                </el-table-column>
                <el-table-column prop="receive" label="Receive" width="100">
                </el-table-column>
                <el-table-column
                  prop="status"
                  label="Status"
                  width="100"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.status == 'process'" size="mini"
                      >Process</el-tag
                    >
                    <el-tag
                      v-if="scope.row.status == 'paid'"
                      type="success"
                      size="mini"
                      >Paid</el-tag
                    >
                    <el-tag
                      v-if="scope.row.status == 'cancel'"
                      type="danger"
                      size="mini"
                      >Cancel</el-tag
                    >
                    <el-tag
                      v-if="scope.row.status == 'pending'"
                      type="warning"
                      size="mini"
                      >Pending</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="uid"
                  label="View"
                  width="80"
                  align="center"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      size="mini"
                      round
                      @click="viewTransaction(scope.row)"
                      >View</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="Topup" name="topup">
              <el-table
                border
                :data="tableTopup"
                size="mini"
                style="width: 100%"
              >
                <el-table-column
                  prop="createdDateFormat"
                  label="Date"
                  width="150"
                  fixed="left"
                >
                </el-table-column>
                <el-table-column
                  prop="currency"
                  label="Topup Currency"
                  align="center"
                  width="120"
                >
                </el-table-column>
                <el-table-column
                  prop="amountOut"
                  label="Amount"
                  align="center"
                  width="100"
                >
                <template slot-scope="scope">
                  {{
                  scope.row.amountOut ? numberFormat(scope.row.amountOut) : ""
                }}
                </template>
           
                </el-table-column>
                <el-table-column
                  prop="customerRef"
                  label="Reference"
                  min-width="180"
                >
                </el-table-column>
                <el-table-column
                  prop="senderName"
                  label="Sender name"
                  min-width="180"
                >
                </el-table-column>

                <el-table-column
                  prop="status"
                  label="Status"
                  width="100"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.status == 'process'" size="mini"
                      >Process</el-tag
                    >
                    <el-tag
                      v-if="scope.row.status == 'pending'"
                      type="warning"
                      size="mini"
                      >Pending</el-tag
                    >
                    <el-tag
                      v-if="scope.row.status == 'success'"
                      type="success"
                      size="mini"
                      >Success</el-tag
                    >
                    <el-tag
                      v-if="scope.row.status == 'topupfail'"
                      type="danger"
                      size="mini"
                      >Topup Fail</el-tag
                    >
                    <el-tag
                      v-if="scope.row.status == 'cancel'"
                      type="danger"
                      size="mini"
                      >Cancel</el-tag
                    >
                    <el-tag
                      v-if="scope.row.status == 'cancelfail'"
                      type="danger"
                      size="mini"
                      >Cancel Fail</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="uid"
                  label="View"
                  width="80"
                  align="center"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      size="mini"
                      round
                      @click="viewTopup(scope.row)"
                      >View</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <WalletReceipt ref="WalletReceipt" />
    <WalletTopupDetail ref="WalletTopupDetail" />
  </div>
</template>

<script>
import WalletPayment from "@/api/wallet/payment";
import WalletReceipt from "@/views/Wallet/components/WalletReceipt";
import WalletTopupDetail from "@/views/Wallet/components/DepositDetail";
import Utils from "@/utils";
export default {
  components: {
    WalletReceipt,
    WalletTopupDetail,
  },
  props: {
    showControl: {
      type: Boolean,
      default: true,
    },
    filter: {
      type: String,
      default: "all",
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      activeName: "transfer",
      tableTransfer: [],
      tableTopup: [],
      dateSearch: "",

      query: {
        startdate: "",
        enddate: "",
        filterType: this.filter,
        order: "DESC",
        limit: this.limit,
      },
    };
  },
  methods: {
    getPaymentHistory() {
      this.loading = true;
      this.getQuery();
      WalletPayment.paymentHistory(this.query).then(({ result, data }) => {
        this.loading = false;
        if (result) {
          this.tableTransfer = data["payment"];
          this.tableTopup = data["topup"];
        }
      }, () => {
        this.loading = false;
        this.tableTransfer = [];
        this.tableTopup = [];
      });
    },
    getQuery() {
      this.query.startdate = this.dateSearch
        ? Utils.dateFormat(this.dateSearch[0], "YYYY-MM-DD")
        : "";
      this.query.enddate = this.dateSearch
        ? Utils.dateFormat(this.dateSearch[1], "YYYY-MM-DD")
        : "";
    },
    viewTransaction(data) {
      this.$refs.WalletReceipt.open(data.uid, data.status);
    },
    viewTopup(data) {
      this.$refs.WalletTopupDetail.open(data);
    },
    numberFormat(number) {
      return Utils.numberFormat(number, 2);
    },
  },
  mounted() {
    if (this.$route.query.tab) { 
      this.activeName = this.$route.query.tab;
    }
    this.getPaymentHistory();
  },
};
</script>

<style lang="scss">
.wallet-history-manage {
  .search-area {
    margin-bottom: 16px;
    button {
      margin-bottom: 16px;
    }
    .el-date-editor {
      min-width: 300px;
      margin-bottom: 16px;
      margin-right: 10px;
      .el-range-separator {
        width: 20%;
      }
    }
    .el-select {
      margin-top: 12px;
      margin-right: 10px;
      margin-bottom: 16px;
    }
  }
}
</style>