<template>
  <div class="wallet-receipt">
    <el-dialog
      title="Payment Confirmed"
      :visible.sync="dialogReceipt"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :width="isMobile ? '95%' : '50%'"
      @closed="onClose"
    >
      <div class="card mb-3 min-vh-25" v-loading="loading">
        <div class="card-body pb-1" v-if="Object.keys(dataReceipt).length > 0">
          <div class="row">
            <div class="col-6">
              <div v-if="showbalance">
              <div>Balance</div>
              <h4 class="pb-0 mb-0">{{balance}} {{ dataReceipt.currency_from }}</h4>
              </div>
            </div>
            <div class="col-6 text-right">
              <el-button
                v-if="createFavoriteBtn"
                type="warning"
                icon="el-icon-star-on"
                size="mini"
                round
                @click="addFavorite"
                >Add Favorite</el-button
              >
              <el-button
                type="success"
                icon="el-icon-download"
                size="mini"
                round
                @click="downloadReceipt"
                >Download</el-button
              >
            </div>
          </div>
        </div>
        <div
          class="card-body text-sm"
          v-if="Object.keys(dataReceipt).length > 0"
        >
          <div class="row justify-content-xl-center">
            <div class="col-lg-8">
              <div class="text-xs">
                We received your payment request at
                {{ dataReceipt.payment_time }}
              </div>

              <div class="card mb-3">
                <div class="card-body p-0 pl-2 pt-2">
                  <div class="grid">
                    <div class="item-left">
                      <el-avatar :size="60">
                        {{ dataReceipt.currency_from }}
                      </el-avatar>
                    </div>
                    <div class="item-right">
                      <h4 class="mb-0">{{ dataReceipt.sender_name }}</h4>
                      <div v-if="upgradePlus">{{ dataReceipt.sender_accountnumberiban }}</div>
                      <div>My {{ dataReceipt.currency_from }} Account</div>
                    </div>
                  </div>
                </div>
              </div>
              <el-divider><i class="el-icon-bottom"></i></el-divider>
              <div class="card mb-3">
                <div class="card-body p-0 pl-2 pt-2">
                  <div class="grid">
                    <div class="item-left">
                      <el-avatar :size="60">
                        {{ dataReceipt.currency_to }}
                      </el-avatar>
                    </div>
                    <div class="item-right">
                      <h4 class="mb-0">{{ dataReceipt.payee_name }}</h4>
                      <div>{{ dataReceipt.payee_accountnumberiban }}</div>
                      <div>{{ dataReceipt.payee_bankname }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-body">
                  <h4 class="mb-0">Payment Amount</h4>
                  <h3 class="text-right mb-0">
                    {{ dataReceipt.amount }} {{ dataReceipt.currency_from }}
                  </h3>
                  <h2 class="text-right mb-0" style="color: #0086b9">
                    {{ dataReceipt.receive }} {{ dataReceipt.currency_to }}
                  </h2>
                  <el-divider></el-divider>
                  <div>
                    Reference : <b>{{ dataReceipt.reference }}</b>
                  </div>
                  <div>
                    Payment ID : <b>{{ dataReceipt.paymentID }}</b>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="text-xs text-center">Payment Status</div>
              <div class="card mb-3">
                <div class="card-body p-0 pt-2">
                  <el-timeline class="p-3">
                    <el-timeline-item
                      icon="el-icon-check"
                      type="success"
                      size="large"
                    >
                      Submit
                    </el-timeline-item>
                    <el-timeline-item
                      icon="el-icon-check"
                      type="success"
                      size="large"
                    >
                      Pending
                    </el-timeline-item>
                    <el-timeline-item
                      icon="el-icon-check"
                      :type="
                        ['process', 'paid', 'cancel'].includes(status)
                          ? 'success'
                          : 'info'
                      "
                      size="large"
                    >
                      Compliance Check
                    </el-timeline-item>
                    <el-timeline-item
                      v-if="status != 'cancel'"
                      icon="el-icon-check"
                      :type="status == 'paid' ? 'success' : 'info'"
                      size="large"
                    >
                      Completed
                    </el-timeline-item>
                    <el-timeline-item
                      v-if="status == 'cancel'"
                      icon="el-icon-close"
                      :type="'danger'"
                      size="large"
                    >
                      Cancel
                    </el-timeline-item>
                  </el-timeline>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import WalletPayment from "@/api/wallet/payment";
import FavoriteApi from "@/api/wallet/favorite";
import BankApi from "@/api/bankaccount";
import { isMobile } from "@/utils/helpers";
export default {
  props: {
    createFavorite: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMobile() {
      return isMobile();
    },
    upgradePlus() {
      return this.$store.getters.feature ? this.$store.getters.feature.upgrade : false;
    },
  },
  data() {
    return {
      loading: false,
      dialogReceipt: false,
      loadingBalance: false,
      balance: "",
      dataReceipt: {},
      status: "",
      showbalance: false,
      createFavoriteBtn: this.createFavorite
    };
  },
  methods: {
    getReceipt(uid) {
      this.loading = true;
      this.dataReceipt = {};
      WalletPayment.paymentReceipt(uid).then(({ result, data }) => {
        this.loading = false;
        if (result) {
          this.dataReceipt = data;
        } else {
          this.dataReceipt = {};
        }
      });
    },
    getBalance(bankholderID) {
      this.loadingBalance = true;
      BankApi.getAccountBalance(bankholderID).then(({ data }) => {
        this.loadingBalance = false;
        this.balance = data['balance'];
      });
    },
    open(uid, status, bankholderID = "") {
      this.loading = true;
      this.dialogReceipt = true;
      this.showbalance = bankholderID ? true : false;
      this.status = status;
      this.getReceipt(uid);
      if (this.showbalance) {
        this.getBalance(bankholderID);
      }
    },
    onClose() {
      this.$emit("onClose");
    },
    addFavorite() {
      this.$prompt("Enter favorite name", "Add Favorite", {
        confirmButtonText: "SAVE",
        cancelButtonText: "Cancel",
        inputValidator: (input) => {
          return input ? true : false;
        },
        inputErrorMessage: "Please enter favorite name",
        beforeClose: (action, instance, done) => {
         
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "Loading...";
            let data = {
              type: "payment",
              favoriteName: instance.inputValue,
              referenceId: this.dataReceipt.payment_UID,
            };

            FavoriteApi.addFavorite(data).then(({ result, message }) => {
              instance.confirmButtonLoading = false;
              instance.confirmButtonText = "SAVE";
              if (result) {
                done();
              } else {
                this.$swal("Message", message, "error");
              }
            });
          } else {
            instance.confirmButtonLoading = false;
            instance.confirmButtonText = "SAVE";
            done();
          }
        },
      })
        .then(({ value }) => {
          this.createFavoriteBtn = false;
          this.$message({
            type: "success",
            message: `Add favorite ${value} is success`,
          });
        })
        .catch((instance) => {
          instance.confirmButtonLoading = false;
          instance.confirmButtonText = "SAVE";
          this.$message({
            type: "info",
            message: "Add favorite canceled",
          });
        });
    },
    downloadReceipt() {
      WalletPayment.downloadPaymentReceipt(this.dataReceipt.payment_UID);
    },
  },
};
</script>

<style lang="scss">
.wallet-receipt {
  .el-avatar {
    background: #0086b9 !important;
  }
  .grid {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: 10px;
    padding: 10px 10px 10px 10px;

    .item-left {
      grid-column-start: 1;
      grid-row: 1 / 1;
      width: 70px;
    }

    .item-right {
      grid-column-start: 2;
      grid-row: 1 / 3;
    }
  }
}
</style>